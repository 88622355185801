import Head from 'next/head'
import Layout from '../components/Layout'
import '../styles/globals.scss'
import icon from '../public/icon.jpg'
import { useEffect,useState,createContext } from 'react'
import { DialogContainer } from '../components/Dialog'
import UserContext from '../components/UserContext'
import { useRouter } from 'next/router'
import {getUser} from '../services/User.service'
import User from '../models/User'
import { cookies } from 'next/headers'
import LoadingState from '../models/Loadingstate'

function MyApp({ Component, pageProps }) {

  const getLayout = Component.getLayout || ((page) => <Layout>{page}</Layout>)
  const [userContext, setUserContext] = useState<User>({
    id: -1,
    version: '0.0.0',
    username: '...',
    firstName: '...',
    lastName: '...',
    email: '...'
  });
  const[loadingState,setLoadingState] = useState<LoadingState>({
    isLoaded: false,
    triedLoading: false,
    reload: false,
  });
  //const [redirectState, setRedirectState] = useState(false);
  const router = useRouter();

  useEffect(() => {
    /*(async () => {
      const res = await fetch('/api/version.php');
      if (res.ok) {
        const data = await res.text();
        const dev_info = document.getElementById('dev_info');
        dev_info.innerHTML = "<p>" + data + "</p>";
      }
    })();
    return () => {

    }; */

    //const tmpCookies:string|undefined = cookies().get('access-token')[0];
      getUser().then((res) => {
        setUserContext(res);
        console.log("USER",res);
      })
      .catch(async (err) => {
        if(err.status == 401 && (router.pathname != '/' && router.pathname != '/user/login' && router.pathname != '/user/register')){
          router.push('/user/login');
        }
        else if(err.status == 500){
          router.push('/Error');
        }
      });

  }, [router.pathname]);

  return (
    <UserContext.Provider value={{userContext, setUserContext,loadingState,setLoadingState}}>

    <Head>
        <title>graduater</title>
        <meta name="description" content="Generated by create next app & export" />
        <link rel="icon" href={icon.src} />
      </Head>
      <DialogContainer />

      <div className='background_img'></div>
      <div>
        <div id="dev_info"><p>No build number</p></div>
        {
          getLayout(<div className="main_container"><Component {...pageProps} /></div>)
        }
      </div>

    </UserContext.Provider>



  )
}

export default MyApp
